import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Box, Container, Typography, Link, Divider } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import Iconify from '../components/Iconify';
import axios from '../utils/axios';

export default function HomeHero() {
  const isDesktop = useResponsive('up', 'lg');

  const [count, setCount] = useState(0);

  const func = async () => {
    const { data } = await axios.get('/visitorCount');
    setCount(data.count);
    
  };

  useEffect(() => {
    func();
  }, []);

  return (
    <Box
      sx={{
        background: 'rgba(223, 241, 255, 0.40)',
        display: 'flex',
        flexDirection: isDesktop ? 'row' : 'column',
        height: '100%',
        width: '100%',
        pt: { xs: 4, sm: 8 },
        pb: 4,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container>
        <Box
          sx={{
            // background: 'rgba(223, 241, 255, 0.40)',
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            // justifyContent:'center',
            alignItems: isDesktop ? '' : 'left',
            ml: isDesktop ? '' : 4,
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: isDesktop ? '' : 'flex',
              justifyContent: isDesktop ? '' : 'center',
              ml: isDesktop ? '' : -4,
            }}
          >
            <img src="/assets/images/auth/start2.webp" alt="err" width="150px" height="70px" />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                color: '#05406C',
                leadingTrim: 'both',
                textEdge: 'cap',
                fontFamily: 'Work Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
              }}
            >
              COMPANY
            </Typography>

            {[
              { id: '/about-us', label: 'About Us' },
              { id: '', label: 'Our Story' },
              { id: '', label: 'Careers' },
              { id: '/contact-us', label: 'Contact' },
              { id: '', label: 'Press Center' },
              { id: '/faqs', label: 'FAQs' },
            ].map((e, i) => (
              <small
                sx={{
                  color: '#181818',
                  leadingTrim: 'both',
                  textEdge: 'cap',
                  fontFamily: ' Work Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: 'normal',
                }}
                key={i}
              >
                <Link
                  to={e.id}
                  key={e.label}
                  color="inherit"
                  variant="body2"
                  component={RouterLink}
                  sx={{ display: 'block' }}
                >
                  {e.label}
                </Link>
              </small>
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                color: '#05406C',
                leadingTrim: 'both',
                textEdge: 'cap',
                fontFamily: 'Work Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
              }}
            >
              FEATURES
            </Typography>
            {[
              'Accounting',
              'Industeries',
              'Bookkeeping',
              'Invoicing',
              'Tax',
              'Funding',
              'Payments',
              'Payrolls',
              'Projects',
              'Reports',
              'Time Tracking',
              'Estimates',
              'Virtual Professional',
            ].map((e, i) => (
              <small
                sx={{
                  color: '#181818',
                  leadingTrim: 'both',
                  textEdge: 'cap',
                  fontFamily: ' Work Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: 'normal',
                }}
                key={i}
              >
                {e}
              </small>
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                color: '#05406C',
                leadingTrim: 'both',
                textEdge: 'cap',
                fontFamily: 'Work Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
              }}
            >
              BUSINESS INDUSTRY
            </Typography>
            {[
              'Accountants',
              'Construction',
              'Marketing & Agencies',
              'Consultants',
              'IT & Technology',
              'Diversity and Inclusivity ',
            ].map((e, i) => (
              <small
                sx={{
                  color: '#181818',
                  leadingTrim: 'both',
                  textEdge: 'cap',
                  fontFamily: ' Work Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: 'normal',
                }}
                key={i}
              >
                {e}
              </small>
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                color: '#05406C',
                leadingTrim: 'both',
                textEdge: 'cap',
                fontFamily: 'Work Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
              }}
            >
              WHO IT’S FOR
            </Typography>
            {[
              'Freelancers',
              'Businesses With Contractors',
              'Businesses With Employees',
              'Self-Employed Professionals',
            ].map((e, i) => (
              <small
                sx={{
                  color: '#181818',
                  leadingTrim: 'both',
                  textEdge: 'cap',
                  fontFamily: ' Work Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: 'normal',
                }}
                key={i}
              >
                {e}
              </small>
            ))}
            <Typography
              sx={{
                color: '#05406C',
                leadingTrim: 'both',
                textEdge: 'cap',
                fontFamily: 'Work Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                mt: 5,
              }}
            >
              POLICIES
            </Typography>
            {[
              { id: '/terms-of-services', label: 'Privacy Policies' },
              { id: '/privacy-policy', label: 'Terms & Conditions' },
              { id: '', label: 'Security Safeguards' },
            ].map((e, i) => (
              <small
                sx={{
                  color: '#181818',
                  leadingTrim: 'both',
                  textEdge: 'cap',
                  fontFamily: ' Work Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: 'normal',
                }}
                key={i}
              >
                <Link
                  to={e.id}
                  key={e.label}
                  color="inherit"
                  variant="body2"
                  component={RouterLink}
                  sx={{ display: 'block' }}
                >
                  {e.label}
                </Link>
              </small>
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                color: '#05406C',
                leadingTrim: 'both',
                textEdge: 'cap',
                fontFamily: 'Work Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
              }}
            >
              CONTACT US
            </Typography>
            {['info@startupkhata.com', 'support@startupkhata.com'].map((e, i) => (
              <small
                sx={{
                  color: '#181818',
                  leadingTrim: 'both',
                  textEdge: 'cap',
                  fontFamily: ' Work Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: 'normal',
                }}
                key={i}
              >
                {e}
              </small>
            ))}
            <Typography
              sx={{
                color: '#05406C',
                leadingTrim: 'both',
                textEdge: 'cap',
                fontFamily: 'Work Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                mt: 5,
              }}
            >
              ADDRESS
            </Typography>
            <small
              sx={{
                color: '#181818',
                leadingTrim: 'both',
                textEdge: 'cap',
                fontFamily: ' Work Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 300,
                lineHeight: 'normal',
              }}
            >
              RZP-56A, First floor, Gali
              <br /> No. 1, Palam Colony,Raj
              <br /> Nagar - 2, New Delhi, Delhi <br />
              110077
            </small>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            borderBottom: '1px solid black',
            width: '100%',
            height: '1%',
            mt: 4,
            mb: 2,
          }}
        />
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Typography
            sx={{
              color: '#05406C',
              leadingTrim: 'both',
              textEdge: 'cap',
              fontFamily: 'Work Sans',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 'normal',
            }}
          >
            Total Visitors :-
          </Typography>
          <Typography
            sx={{
              color: '#181818',
              leadingTrim: 'both',
              textEdge: 'cap',
              fontFamily: ' Work Sans',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 300,
              lineHeight: 'normal',
              textAlign: 'center',
            }}
          >
            {count}
          </Typography>
          <Divider />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            alignItems: 'center',
            gap: 1,
            justifyContent: 'space-between',
            flexShrink: 0,
          }}
        >
          <Typography
            sx={{
              color: '#05406C',
              fontFamily: 'Work Sans',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 300,
              lineHeight: '20px',
            }}
          >
            © 2023, Startup Khata. All Rights Reserved.
          </Typography>
          <Box>
            <Iconify icon="logos:facebook" sx={{ height: '35px', width: '35px', pl: 2 }} />
            <Iconify icon="skill-icons:instagram" sx={{ height: '35px', width: '35px', pl: 2 }} />
            <Iconify icon="logos:youtube-icon" sx={{ height: '35px', width: '35px', pl: 2 }} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
